
(function () {

    // Init des sliders
    const sliders = document.querySelectorAll('.js-slider-ancres');
    sliders.forEach(function(slider) {
        initSlider(slider);
    });

    function initSlider(slider) {
        const splide = new Splide(slider, {
            autoHeight: true,
             focus: 0,
           omitEnd: true,
           //  trimSpace: 'move',
        }).mount();
    }

})();
