(function () {

    // Init des sliders
    const sliders = document.querySelectorAll('.js-slider-galerie');
    sliders.forEach(function(slider) {
        initSlider(slider);
    });

    function initSlider(slider) {
        const sliderDirection = slider.classList.contains('--direction-ltr') ? 'ltr' : 'rtl';

        const splide = new Splide(slider, {
            lazyLoad: 'nearby',
            focus: 0,
            omitEnd: true,
            direction: sliderDirection,

            breakpoints: {
                600: {
                    direction: 'ltr',
                },
            }
        }).mount();
    }

})();