//spécificités listing Agenda
parseCardAgendaClickOverlay();

// OVERLAY MAP
function callbackMapOverlayMap(macarte) {
    macarte.zoomControl.setPosition('topright');
}


// PAGE LISTING SIT
function callbackMapPageListingLEI(macarte) {
    macarte.zoomControl.setPosition('topright');
}


// listing SIT classique - uniquement une map en overlay
var listingSitDefault = new thListingManager({
    selectors: {
        mapoverlay: '.js-listing-overlay-map',
    },
    mapOptions: {
        onlyDesktop: true,
    },
    mapOverlayOptions: {
        onlyMobile: false,
        onlyDesktop: false,
    },
    // update_form: true, // permet de mettre à jour les valeurs de formulaire en fonction du filtrage utilisateur
    afterUpdate: function () {
        // si composant favoris :
        // th_favoris.parseItems();
        // th_favoris.addEvents();
        // $('.facette-radio, .facette-checkbox label, .facette-switch').append('<span></span>');
    }
});

// listing SIT classique - uniquement map en aside
var listingSitDefaultWithMap = new thListingManager({
    selectors: {
        listing: ".js-listing-default-map",
        mapoverlay: '.js-listing-overlay-map',
        map: '.js-listing-map',
    },
    mapOptions: {
        onlyDesktop: true,
    },
    mapOverlayOptions: {
        onlyMobile: false,
    },
    // update_form: true, // permet de mettre à jour les valeurs de formulaire en fonction du filtrage utilisateur
    afterUpdate: function () {
        // si composant favoris :
        // th_favoris.parseItems();
        // th_favoris.addEvents();
        // $('.facette-radio, .facette-checkbox label, .facette-switch').append('<span></span>');
    }
});

// listing SIT FMA - uniquement une map en overlay + overlay sur les cards
var listingSitFMA = new thListingManager({
    selectors: {
        listing: '.js-listing-fma',
        mapoverlay: '.js-listing-overlay-map',
    },
    mapOverlayOptions: {
        onlyMobile: false,
        onlyDesktop: true,
    },
    afterUpdate: function () {
        // si composant favoris :
        // th_favoris.parseItems();
        // th_favoris.addEvents();
        parseCardAgendaClickOverlay();
    }
});

// listing CPT - pas de map
var listingCPT = new thListingManager({
    selectors: {
        listing: '.js-listing-cpt',
    },
    afterUpdate: function () {
        // si composant favoris :
        // th_favoris.parseItems();
        // th_favoris.addEvents();
    }
});

/* A l'ouverture de l'overlay, on charge la carte */
// cette fonction doit $etre déclarée avant le th_overlay.init()
th_overlay.addCallbackOpen(function (overlayId) {
    if (overlayId == 'overlay-map') {
        setTimeout(function () {
            if (listingSitDefaultWithMap.loaded) {
                listingSitDefaultWithMap.initMapOverlay();
            }
            if (listingSitDefault.loaded) {
                listingSitDefault.initMapOverlay();
            }
            if (listingSitFMA.loaded) {
                listingSitFMA.initMapOverlay();
            }
        }, 500);
    }
});


/* Initialisation du slider de la carte */

(function () {

    var sliders = document.querySelectorAll('.overlay-map .th-slider');
    for (i = 0; i != sliders.length; i++) {
        var slider = sliders[i];
        initSlider(slider);
    }

    function initSlider(slider) {
        var parent = slider.parentNode;

        var navButtons = parent.querySelectorAll('.nav-buttons button');
        var dotsWrapper = parent.querySelectorAll('.nav-dots');

        var s = new thSlider(slider, {
            draggable: true,
            scrollListener: true,
            scrollModeMaxWidth: '1024',
            oninit: function (slider) {

                if (navButtons.length === 2) {
                    thSliderTools.onInitNav(slider, navButtons);
                }

                if (dotsWrapper) {
                    thSliderTools.onInitDots(slider, dotsWrapper[0]);
                }

            },
            onchange: function (slider) {
                if (navButtons.length > 0) {
                    thSliderTools.onChangeNavIgnoreLastItemIn(slider, navButtons);
                }
                if (dotsWrapper) {
                    thSliderTools.onChangeDots(slider, dotsWrapper[0]);
                }
            }
        });

    }
})();


var sliders = document.getElementsByClassName('filterRange');

for (var i = 0; i < sliders.length; i++) {

    var min = parseInt(sliders[i].getAttribute('data-min'));
    var max = parseInt(sliders[i].getAttribute('data-max'));
    var start = parseInt(sliders[i].getAttribute('data-start'));
    var end = parseInt(sliders[i].getAttribute('data-end'));
    var datarange = JSON.parse(sliders[i].getAttribute('data-range').replace(/gu/g, '"'));
    datarange.min = min;
    datarange.max = max;

    noUiSlider.create(sliders[i], {
        start: [start, end],
        snap: true,
        connect: true,
        tooltips: true,
        format: {
            to: function (value) {
                return value;
            },
            from: function (value) {
                return value;
            }
        },
        range: datarange,
    });

    sliders[i].noUiSlider.on('change', addValues);
}

function addValues() {
    for (var i = 0; i < sliders.length; i++) {
        var key = sliders[i].getAttribute('data-key');
        var val_slider = sliders[i].noUiSlider.get();
        $('input[name=' + key + '_min]').val(val_slider[0]);
        $('input[name=' + key + '_max]').val(val_slider[1]).trigger('force-change');
    }
    ;
}
