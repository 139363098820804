(function () {
    class BlocCarteInteractive {
        constructor(groupNode) {
            // Init du slider
            const slider = groupNode.querySelector('.js-slider-carte-interactive');
            this.splide = new Splide(slider, {
                lazyLoad: 'nearby',
                focus: 0,
                omitEnd: true,
            }).mount();

            this.map = groupNode.querySelector('.svg-canvas');
            this.hotspots = this.map.querySelectorAll('#map-svg .zone');
            this.villes = this.map.querySelectorAll('#map-svg [data-zone]')
            this.currentId = 1;     // 1ere zone par défaut
            this.init();
        }

        init() {
            // Click sur une des zones de la map
            this.hotspots.forEach(hotspot => {
                hotspot.addEventListener('click', this.onClickHotspot.bind(this));
            });

            // Changement de slide -> active / désactive les hotspots
            this.splide.on('active', Slide => {
                this.currentId = Slide.index + 1;       // index à partir de 0 pour Splide
                this.setSelectedHotspot();
            });

            // Sélectionne la 1ere zone
            this.setSelectedHotspot();
        }

        // Click sur une des zones de la map -> va au slide correspondant
        onClickHotspot(e) {
            const hotspot = e.currentTarget;

            let idString = hotspot.id;      // id="zone1"
            idString = idString.match(/\d/g);
            let id = Number(idString.join(''));
            this.splide.go(id - 1);         // index à partir de 0 pour Splide
        }

        // Active / désactive le hotspot courant
        setSelectedHotspot() {
            this.hotspots.forEach((hotspot) => {
                let idString = hotspot.id;      // id="zone1"
                idString = idString.match(/\d/g);
                let id = Number(idString.join(''));

                if (this.currentId === id) {
                    hotspot.classList.add('is-active');
                } else {
                    hotspot.classList.remove('is-active');
                }
            });
        }
    }

    // Init des blocs
    const blocs = document.querySelectorAll('.bloc-carte-interactive');
    blocs.forEach(function (bloc) {
        new BlocCarteInteractive(bloc);
    });

})();

