// Selectric est activé par défaut sur toutes les selects,
// mais nous on le veut pas avoir sur le sélecteur de langues en version tablette / mobile
$('.js-lang-dropdown-mobile').selectric('destroy');

// Ce script est nécessaire pour forcer le changement de page au click sur une option
$('.js-lang-dropdown-mobile').change(function () {
    var val = $(this).val();
    window.location.href = val;
});

// Ouvre le megamenu sur le menu dans la barre de nav en desktop
$('label.barre-navigation__menu-link').on('click', function (e) {
    e.preventDefault();     // supprime le comportement par défaut du label (saut haut de page)
    var id = $(this).attr('for').substr(-1);
    $('#nav-primary-rb-'+id).trigger("click");

    // ouvre le megamenu sans le hash dans l'url
    if(!$('#overlay-menu').hasClass('open')) {
        th_overlay.open('overlay-menu', true, true, false);
    }
});