(function () {

    // Init
    const select = document.querySelector('.js-select-contact');
    const items = document.querySelectorAll('.item-contact');
    let currentId = select.value;

    $(select).on('change', function() {
        currentId =  $(this).val();
        updateContact();
    });

    updateContact();

    // Affiche / masque les zones de contact
    function updateContact() {
        items.forEach(function(el) {
            if(el.dataset.id == currentId) {
                el.classList.add('is-active');
            } else {
                el.classList.remove('is-active');
            }
        });
    }

})();