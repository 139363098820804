$('[name="nav-primary-choices"]').on('change', function(e){
    var id = $(this).attr('id');

    if($(this).prop('checked')){
        $('[name="nav-primary-choices"]').prop( "checked", false );
        $('[for^="nav-primary-rb-"]').removeClass('actif');

        $(this).prop('checked', true);
        $('[for="'+id+'"]').addClass('actif');
    }else{
        $('[name="nav-primary-choices"]').prop( "checked", false );
        $('[for^="nav-primary-rb-"]').removeClass('actif');
    }
});

let dropdown = $('.language-list');
$('.js-lang-dropdown-mobile-mega-menu').on('click', function () {
    if(dropdown.hasClass('.is-open')){
        dropdown.removeClass('.is-open');
        dropdown.css('opacity', '0');
    }else{
        dropdown.addClass('.is-open');
        dropdown.css('opacity', '1');
    }
});

