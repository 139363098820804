(function () {

    // Init des sliders
    const sliders = document.querySelectorAll('.js-slider-sit-horaires');
    sliders.forEach(function (slider) {
        initSlider(slider);
    });

    function initSlider(slider) {
        const splide = new Splide(slider, {
            autoHeight: true,
            lazyLoad: 'nearby',
            focus: 0,
            omitEnd: true,
            autoWidth: true,
        }).mount();
    }

})();

