(function () {

    // Init des sliders
    var sliders = document.querySelectorAll('.js-slider-cover-home');
    sliders.forEach(function(slider) {
        initSlider(slider);
    });

    function initSlider(slider) {
        var splide = new Splide(slider, {
            easing: 'cubic-bezier(0.5, 1, 0.89, 1)',
            pagination: true,
            arrows: false,
            autoHeight: true,
            lazyLoad: 'nearby',
            autoplay: true,
            interval: 8000,
            type: 'loop',
            pauseOnHover: false,
            pauseOnFocus: false,
        });

        splide.on("ready", function () {
            appendSVGElement();
            updateSliderProgress();
        });
        splide.mount();


        function updateSliderProgress() {
            var sliderDuration = 8000;
            var progressBarList = $(".splide__pagination__page");

            progressBarList.each(function () {
                var progressBar = $(this).find(".js-progress-bar");
                var progressLine = $(this).find(".js-progress-line");
                progressBar.stop();
                progressLine.stop();
                if (!$(this).hasClass("is-active")) {
                    progressBar.css("stroke-dashoffset", "100px");
                    progressLine.css('rotate', '0deg');
                } else {
                    progressBar.animate({'stroke-dashoffset': 0}, sliderDuration, 'linear');
                    progressLine.css('transition', 'all ' + sliderDuration + 'ms' + ' linear');
                    progressLine.css('rotate', '360deg');
                }
            });
        }

        function appendSVGElement() {
            var dots = $(".cover-home .splide__pagination__page");
            var svgShape = $(".cover-home #svg-progress-shape");
            var svgShapeClone = $(".cover-home #svg-progress-shape").clone();
            dots.each(function () {
                var currentShape = svgShapeClone.clone();
                $(this).append(currentShape);
            });
            svgShape.remove();
        }

        splide.on('move', function (e) {
            updateSliderProgress();
        });

    }

})();