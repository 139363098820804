function initWebsiteSearch() {

    var $input = $('.overlay-search input');
    var $buttonExplore = $('.overlay-search .button-explore');
    var $searchWrapper = $('.container-resultats');
    var timeoutAlgoliaClass = null;

    // Ajoute une class à la balise form pour annuler le curseur qui clignotte
    $input.focusin(function () {
        $(this).parents('form:first').addClass('focused');
    });

    $buttonExplore.on('click', function () {
        setTimeout(function () {
            $input.focus();
            $('.overlay-search form').addClass('focused');
        }, 500);
    });

    // var iScrollBarAlgolia = null;
    if (hasIscroll()) {
        th_overlay.addCallbackOpen(function (overlayId) {
            $input.focus();

        });
    }

    setTimeout(function () {
        // init de algolia
        thuriaSearch.init(
            algolia.application_id,
            algolia.search_api_key,
            ['wp_posts_actualites', 'wp_posts_page', 'wp_posts_apidae', 'wp_posts_geotrek'],
            $('#input-search-algolia').get(0),
            $('#algolia-results').get(0),
            $('#overlay-search').get(0)
        );

        thuriaSearch.setLanguage(algolia_language.fulllang);

    }, 1000);

    th_overlay.addCallbackOpen(function (overlayId) {
        setTimeout(function () {
            $input.get(0).focus();
        }, 500);

    });

}

if ($('.overlay-search').length > 0) {
    initWebsiteSearch();
}