(function () {

    // ————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————
    // Graph Altimètre GEOTREK

    if ($('#denivele').length > 0) {

        // Chart.js, lourd (170ko) mais beaucoup d'option dont des interractions
        // https://www.chartjs.org/

        //Récupération de la couleur de la page, et rajout de 80 à la fin du #hexa pour mettre la transparent de 50% pour le fill
        var chartColors = "#105540";
        var bgChartCurve = "#EDF060";

        //Initialisation du graph avec les valeurs en DATA sur l'élément canvas généré en PHP en fonction des informations fournis dans le KML
        var YAxis = $('#denivele').attr('data-YAxis').split(',');
        //# 13039 axe des ordonnées minimum à 100
        //YAxis.push(100);
        var XAxis = $('#denivele').attr('data-XAxis').split(',');
        var ctx = $('#denivele');
        var myChart = new Chart(ctx, {
            type: 'line',
            data: {
                labels: XAxis,
                datasets: [
                    {
                        label: 'DÉNIVELÉ (m)',
                        data: YAxis,
                        borderColor: bgChartCurve,
                        fill: true,
                        backgroundColor: bgChartCurve,
                        pointRadius: 0,
                        pointHoverRadius: 5,
                        pointHitRadius: 10,
                        pointBackgroundColor: "#103A2E",
                        pointBorderColor: "#002C2E",
                        pointBorderWidth: 2,
                        lineTension: 0.1,
                    }
                ]
            },

            hover: {
                mode: 'index'
            },
            options: {
                responsive: true,
                legend: {
                    display: false,
                },
                maintainAspectRatio: false,
                scales: {
                    xAxes: [{
                        display: true,
                        ticks: {
                            beginAtZero: true,
                            stepSize: 10,
                            fontColor: "#FFF",
                            maxTicksLimit: 20
                        },
                        scaleLabel: {
                            display: true,
                            labelString: 'Distance (km)',
                            //fontStyle: "bold",
                            fontFamily: 'muli',
                            fontColor: "#FFFFFF"
                        },
                        gridLines: {
                            drawOnChartArea: false
                        }
                    }],
                    yAxes: [{
                        display: true,
                        ticks: {
                            beginAtZero: true,
                            stepSize: 60,
                            fontColor: "#FFF"
                        },
                        gridLines: {
                            drawOnChartArea: true,
                            borderDash: [1, 5],
                            color: "#BFE8C0"
                        },
                        scaleLabel: {
                            display: true,
                            labelString: 'Altitude (m)',
                            fontFamily: 'muli',
                            //fontStyle: "bold",
                            fontColor: "#FFFFFF",
                            padding: {
                                bottom: 20
                            }
                        }
                    }],
                },
                tooltips: {
                    bodyFontFamily: 'muli',
                    titleFontFamily: 'muli',
                    displayColors: false,

                }
            },
        });

        document.getElementById("denivele").onmousemove = function (evt) {
            var activePoint = myChart.getElementAtEvent(event);

            // make sure click was on an actual point
            if (activePoint.length > 0) {
                $('[data-markerindex=' + activePoint[0]._index + ']').addClass('active');
                //$('#overlay-itineraire-etape').addClass('open');
                // $('.shadow-overlay').addClass('open');
            } else {
                $('[data-markerindex]').removeClass('active');
            }
        };
    }

    // ————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————
    // Init des sliders
    const sliders = document.querySelectorAll('.js-slider-cover-single-sit');
    sliders.forEach(function (slider) {
        initSlider(slider);
    });

    function initSlider(slider) {
        const splide = new Splide(slider, {
            autoWidth: true,
            lazyLoad: 'nearby',
            focus: 0,
            omitEnd: true,

            breakpoints: {
                600: {
                    arrows: false,
                },
            }
        }).mount();
    }

    // ————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————
    // Gestion du voir plus/moins au niveau de la description de la fiche SIT
    var sitProposHeight = $('.single-sit .sit-a-propos .text');
    var btnShowMorePropos = $('.single-sit .btn-view-more');

    sitProposHeight.each(function () {
        if ($(this).height() >= 188) {
            $(this).parent().addClass('view-more-active');

            var currentAriaExpanded = false;
            btnShowMorePropos.on('click', function () {
                currentAriaExpanded = !currentAriaExpanded;

                $(this).prev().toggleClass('toggle-height');

                if (currentAriaExpanded) {
                    $(this).attr("aria-expanded", true);
                } else {
                    $(this).attr("aria-expanded", false);
                }

            });
        }
    });


})();


(function () {

    // Init des sliders
    const sliders = document.querySelectorAll('.js-slider-galerie-sit');
    sliders.forEach(function (slider) {
        initSlider(slider);
    });

    function initSlider(slider) {
        const splide = new Splide(slider, {
            autoHeight: true,
            lazyLoad: 'nearby',
            focus: 0,
            omitEnd: true,
            direction: "rtl",

            breakpoints: {
                600: {
                    arrows: false,
                },
            }
        }).mount();
    }

})();

