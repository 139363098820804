function initMasonry() {
    $(document).ready(function () {
        var wrapperMosaic = document.querySelector('.wrapper-mosaic');
        if (wrapperMosaic) {
            var masonryListing = new Masonry(wrapperMosaic, {
                // options
                itemSelector: '.card-container',
                columnWidth: '.card-container',
                percentPosition: true,
                horizontalOrder: true
            });
        }
    });
}

initMasonry();







